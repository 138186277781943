<template>
  <section class="info-window">

    <div class="info-window-heading__container">
      <svg class="info-window-icon" width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.5002 46.4166C36.1043 46.4166 46.4168 36.1041 46.4168 23.4999C46.4168 10.8958 36.1043 0.583252 23.5002 0.583252C10.896 0.583252 0.583496 10.8958 0.583496 23.4999C0.583496 36.1041 10.896 46.4166 23.5002 46.4166ZM21.2085 12.0416H25.7918V16.6249H21.2085V12.0416ZM28.0835 34.9582H18.9168V30.3749H21.2085V25.7916H18.9168V21.2083H25.7918V30.3749H28.0835V34.9582Z" fill="#67247C"/>
      </svg>
      <p class="info-window__heading label">{{ infoTitle }}</p>
    </div>

    <p class="info-window__content">{{ infoText }}</p>

    <div class="info-window-button-container">
      <v-btn @click="$emit('closeInfoWindow')" class="accent">I understand</v-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: "MapInfoWindow",

  props: {
    infoText: {
      type: String,
      required: false
    },
    infoTitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.info-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: justify;
  padding: 16px;

  width: 500px;
  height: 300px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;

  &__heading {
    font-size: 20px;
    text-transform: uppercase;
  }

  &-heading__container {
    display: flex;
    align-items: center;
  }

  &__content {
    font-size: 14px;
    font-weight: 400;
    color: black;
  }

  &-icon {
    widows: 24px;
    height: 24px;
  }
}
</style>