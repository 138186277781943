<template>
  <section v-if="data" class="map-card">
    <div class="map-card__intro">
      <h2 class="map-name">{{ data.attributes.title }}</h2>

      <div class="categories-wrapper">
        <div v-for="category in categoriesArray" :key="category.index">
          <p class="selected-categories">{{ category }}</p>
        </div>
      </div>
    </div>

    <div class="map-card__actions">
      <button class="map-card__action" @click="editMap(data.id)">
        <v-icon>edit</v-icon>
      </button>
      <button class="map-card__action" @click="deleteMap()">
        <v-icon>delete</v-icon>
      </button>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "MapDirectoryCard",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    api: process.env.VUE_APP_MAPDIRECTORYAPI,
    categoriesArray: [],
  }),
  created() {
    this.data.attributes.categories.forEach((categoryId) => {
      this.fetchSingleCategory(categoryId);
    });
  },

  methods: {
    editMap(id) {
      this.$router.push({ path: `/map-directory/${id}` });
    },
    deleteMap() {
      this.$emit("deleteMap");
      console.warn("Delete Map Triggered for:", this.data.name);
    },

    fetchSingleCategory(categoryId) {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/categories/${categoryId}`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log(
              "single category data",
              response.data.data.attributes.title
            );

            this.categoriesArray.push(response.data.data.attributes.title);
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem receiving category data", e);
            this.$store.commit("completeLoading");
          });
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.map-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 15px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 100px;

  &__intro {
    flex-grow: 1;
    margin: 16px 16px 8px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    background-color: #e6e8f1;
    height: 100%;
    padding: 24px 16px;
  }

  &__action {
    margin: 0 8px 0 0;

    i {
      color: #67247c;
    }
  }
}

.map-name {
  font-size: 16px;
  color: #516173;
}

.map-label {
  font-size: 14px;
  color: #a8aab7;
}
.map-type {
  background-color: #f4f6d2;
  border: 1px solid #d6d8b0;
  width: auto;
  padding: 2px 4px;
  border-radius: 6px;
}

.selected-categories {
  position: relative;
  top: 10px;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100px;
  background-color: #aecbe1;
  color: white;
  font-size: 12px;
  font-weight: 400;
  margin: 2px;
  border-radius: 4px;
  padding: 0 0 0 10px;
}

.categories-wrapper {
  display: flex;
}
</style>
