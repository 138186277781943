<template>
  <section>
    <div class="button-wrapper">
      <v-btn type="button" depressed @click="addNew()" class="accent">
        <v-icon small>add</v-icon>
        Add Map
      </v-btn>

      <!-- <v-select class="filters"
        v-if="organisationTypeFilters"
        v-model="selectedType"
        :items="organisationTypeFilters"
        placeholder="Filter by type"
        flat
        @change="filterOrganisations()"
        clearable >
      </v-select> -->
    </div>

    <map-directory-card
      v-for="map in mapsArray"
      :key="map.mapID"
      :data="map"
      @deleteMap="deleteMap(map.id)"
    />

    <div v-if="mapsArray < 1" class="map-icon-container">
      <img src="../../assets/map-directory/MapUI.svg" alt="" />
      <p>0 Maps Exist</p>
    </div>
  </section>
</template>

<script>
import { consoleSandbox, isThenable } from "@sentry/utils";
import axios from "axios";
import MapDirectoryCard from "../../components/MapDirectory/MapDirectoryCard/MapDirectoryCard.vue";

export default {
  name: "MapDirectoryList",

  components: {
    MapDirectoryCard,
  },

  data: () => ({
    api: process.env.VUE_APP_MAPDIRECTORYAPI,
    mapsArray: null,
  }),

  mounted() {
    this.fetchMapsArray();
  },

  methods: {
    addNew() {
      this.$router.push({ path: "/map-directory/create" });
    },

    fetchMapsArray() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/maps`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.mapsArray = response.data.data;
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.log("Error fetching maps array", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    // filterOrganisations() {
    //   if (!this.selectedType && this.sortBy == 'Alphabetical') {
    //     this.getOrganisations()
    //   } else {
    //     this.$store.commit('startLoading')
    //     this.$getCurrentUserJwToken().subscribe((jwt) => {

    //       let selectedType = '';
    //       if (this.selectedType) {
    //         selectedType = `organisationTypeID=${this.selectedType}`
    //       } else {
    //         selectedType = ''
    //       }

    //       let sortBy = '';
    //       if (this.sortBy == 'Alphabetical') {
    //         sortBy = '&sort=alpha'
    //       } else {
    //         sortBy = '&sort=position'
    //       }

    //       axios({
    //         method: 'GET',
    //         url: `${this.api}/organisations?${selectedType}${sortBy}&pageSize=1000`,
    //         headers: { 'Authorization': jwt }
    //       })
    //       .then(response => {
    //         console.log('Orgs filtered', response)

    //         this.organisations = response.data.body.Items
    //         this.$store.commit('completeLoading')
    //       })
    //       .catch(e => {
    //         console.error('Problem receiving orgs', e)
    //         this.$store.commit('completeLoading')
    //       })
    //     })
    //   }
    // },

    // getOrganisationTypeFilters() {
    //   this.$getCurrentUserJwToken().subscribe((jwt) => {
    //     axios({
    //       method: 'GET',
    //       url: `${this.api}/organisationtypes`,
    //       headers: { 'Authorization': jwt }
    //     })
    //     .then(response => {
    //       response.data.body.Items.forEach(item => {
    //         this.organisationTypeFilters.push(item.organisationTypeID)
    //       })
    //     })
    //     .catch(e => {
    //       console.error('Problem receiving orgtypes', e)
    //     })
    //   })
    // },

    deleteMap(id) {
      if (
        confirm(
          "Are you sure you want to delete this Map? This is irreversible."
        )
      ) {
        this.$store.commit("startLoading");
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          console.log("deleting:", id);

          axios({
            method: "DELETE",
            url: `${this.api}/maps/${id}`,
            headers: { Authorization: jwt },
          })
            .then((response) => {
              console.log("Map deleted", response);

              this.fetchMapsArray();
              this.$store.commit("completeLoading");
            })
            .catch((e) => {
              console.error("Problem deleting map", e);
              this.$store.commit("completeLoading");
            });
        });
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filters {
  max-width: 250px !important;
}

.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
  width: calc(100% - (var(--body-gutter) * 2));
}

.map-icon {
  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
</style>
