<template>
  <section>
    <v-tabs v-model="activeTab">
      <v-tab :ripple="{ class: 'accent--text' }">Maps</v-tab>

      <v-tab-item px-0 class="main-body">
        <div class="map-info-window-container">
          <p class="map-label">
            Select a Map to view details or to populate your map with
            categories.
          </p>

          <svg
            @click="isInfoWindowMap = true"
            class="map-info-window-icon"
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.5002 46.4166C36.1043 46.4166 46.4168 36.1041 46.4168 23.4999C46.4168 10.8958 36.1043 0.583252 23.5002 0.583252C10.896 0.583252 0.583496 10.8958 0.583496 23.4999C0.583496 36.1041 10.896 46.4166 23.5002 46.4166ZM21.2085 12.0416H25.7918V16.6249H21.2085V12.0416ZM28.0835 34.9582H18.9168V30.3749H21.2085V25.7916H18.9168V21.2083H25.7918V30.3749H28.0835V34.9582Z"
              fill="#67247C"
            />
          </svg>

          <map-info-window
            :infoTitle="mapInfo.title"
            :infoText="mapInfo.text"
            v-if="isInfoWindowMap"
            class="map-info-window fade-in-image"
            @closeInfoWindow="isInfoWindowMap = false"
          />
        </div>

        <section class="section-container">
          <map-directory-list />
        </section>
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Categories</v-tab>

      <v-tab-item px-0 class="main-body">
        <div class="map-info-window-container">
          <p class="map-label">
            Categories allow you to create and group location pins into a
            'Category' such as Stadium or Academies.
          </p>

          <svg
            @click="isInfoWindowMap = true"
            class="map-info-window-icon"
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.5002 46.4166C36.1043 46.4166 46.4168 36.1041 46.4168 23.4999C46.4168 10.8958 36.1043 0.583252 23.5002 0.583252C10.896 0.583252 0.583496 10.8958 0.583496 23.4999C0.583496 36.1041 10.896 46.4166 23.5002 46.4166ZM21.2085 12.0416H25.7918V16.6249H21.2085V12.0416ZM28.0835 34.9582H18.9168V30.3749H21.2085V25.7916H18.9168V21.2083H25.7918V30.3749H28.0835V34.9582Z"
              fill="#67247C"
            />
          </svg>

          <map-info-window
            :infoTitle="categoriesInfo.title"
            :infoText="categoriesInfo.text"
            v-if="isInfoWindowMap"
            class="map-info-window fade-in-image"
            @closeInfoWindow="isInfoWindowMap = false"
          />
        </div>

        <section class="section-container">
          <map-categories-list @categoryPinColour="getCategoryPinColour" />
        </section>
      </v-tab-item>
    </v-tabs>
  </section>
</template>

<script>
import MapDirectoryList from "../../components/MapDirectory/MapDirectoryList.vue";
import MapCategoriesList from "../../components/MapDirectory/Categories/MapCategoriesList.vue";
import MapInfoWindow from "../../components/MapDirectory/MapInfoWindow/MapInfoWindow.vue";

export default {
  name: "MapDirectoryManager",

  components: {
    MapDirectoryList,
    MapCategoriesList,
    MapInfoWindow,
  },

  data: () => ({
    activeTab: null,
    categoryPinColour: null,
    isInfoWindowMap: false,
    mapInfo: {
      text: "In the Maps section select ADD MAP to create a Map, set its location and populate your new Map with categories.",
      title: "Maps",
    },
    categoriesInfo: {
      text: "In the Categories section select ADD CATEGORY to create a group of location pins. For example, you can create a Stadiums Category, select the colour that will represent Stadiums on your map and add locations pins into this Category.",
      title: "Categories",
    },
  }),

  created() {
    if (this.$route.query.tab) {
      this.activeTab = Number(this.$route.query.tab);
    }
  },

  methods: {
    getCategoryPinColour(payload) {},
  },
};
</script>

<style scoped lang="scss">
.map-label {
  font-size: 14px;
  color: #757575;
}

.map-info-window-container {
  display: flex;
  position: relative;
}

.map-info-window-icon {
  width: 20px;
  height: 20px;
  position: relative;
  left: 5px;
  top: -5px;
  cursor: pointer;
}

.map-info-window {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: ease-in-out;
}

.fade-in-image {
  animation: fadeIn 300ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
