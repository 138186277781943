<template>
  <section v-if="data" class="category-card">
    <div class="category-card__intro">
      <div class="category-card__heading">
        <svg
          class="category-card__icon"
          width="26"
          height="30"
          viewBox="0 0 26 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 15.2866C13.8594 15.2866 14.5953 14.987 15.2078 14.3878C15.8203 13.7885 16.126 13.069 16.125 12.2293C16.125 11.3885 15.8187 10.6685 15.2062 10.0693C14.5937 9.47006 13.8583 9.17095 13 9.17197C12.1406 9.17197 11.4047 9.47159 10.7922 10.0708C10.1797 10.6701 9.87396 11.3896 9.875 12.2293C9.875 13.0701 10.1813 13.7901 10.7938 14.3893C11.4063 14.9885 12.1417 15.2876 13 15.2866ZM13 30C12.7917 30 12.5833 29.9618 12.375 29.8853C12.1667 29.8089 11.9844 29.707 11.8281 29.5796C8.02604 26.293 5.1875 23.2418 3.3125 20.426C1.4375 17.6102 0.5 14.9799 0.5 12.535C0.5 8.71338 1.75677 5.66879 4.27031 3.40127C6.78385 1.13376 9.69375 0 13 0C16.3073 0 19.2177 1.13376 21.7312 3.40127C24.2448 5.66879 25.501 8.71338 25.5 12.535C25.5 14.9809 24.5625 17.6117 22.6875 20.4275C20.8125 23.2433 17.974 26.294 14.1719 29.5796C14.0156 29.707 13.8333 29.8089 13.625 29.8853C13.4167 29.9618 13.2083 30 13 30Z"
            :fill="data.attributes.colour"
          />
        </svg>
        <h2 class="category-card__title">{{ data.attributes.title }}</h2>
      </div>

      <p class="category-card__locations"></p>

      <button class="category-card__button" @click="pushLocations(data.id)">
        Select to view details or add location pins
      </button>
    </div>

    <div class="category-card__actions">
      <button class="category-card__action" @click="editCategory(data.id)">
        <v-icon>edit</v-icon>
      </button>
      <button class="category-card__action" @click="deleteCategory()">
        <v-icon>delete</v-icon>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "MapCategoriesCard",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    editCategory(id) {
      console.log("Edit Category Run");
      this.$router.push({ path: `/map-directory/categories/:${id}` });
    },
    deleteCategory() {
      this.$emit("deleteCategory");
      console.warn(
        "Delete Category Triggered for:",
        this.data.attributes.title
      );
    },
    pushLocations(id) {
      this.$router.push({
        path: `/map-directory/categories/${id}/locations-list`,
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.category-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 80px;

  &__intro {
    flex-grow: 1;
    margin: 16px 16px 8px;
  }

  &__actions {
    background-color: #e6e8f1;
    height: 100%;
    padding: 24px 16px;
  }

  &__action {
    margin: 0 8px 0 0;

    i {
      color: #67247c;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    color: #516173;
    font-weight: 500;
    margin: 0 0 0 5px;
    text-transform: capitalize;
  }

  &__icon {
    width: 16px;
    height: 19px;
  }

  &__locations {
    font-size: 14px;
    font-weight: 400;
    color: #a8aab7;
  }

  &__button {
    position: relative;
    top: -10px;
    font-size: 10px;
    font-weight: 400;
    color: #0d99ff;
    text-transform: uppercase;
  }
}

.category-type {
  background-color: #f4f6d2;
  border: 1px solid #d6d8b0;
  width: auto;
  padding: 2px 4px;
  border-radius: 6px;
}
</style>
