<template>
  <section>
    <div class="button-wrapper">
      <v-btn type="button" depressed @click="addNew()" class="accent">
        <v-icon small>add</v-icon>
        Add Category
      </v-btn>
    </div>

    <!-- <map-directory-card v-for="map in mapsArray" :key="map.mapID" :data="map" @deleteMap="deleteMap(map.id)" /> -->

    <map-categories-card
      v-for="category in categoriesArray"
      :key="category.id"
      :data="category"
      @deleteCategory="deleteCategory(category.id)"
    />

    <div v-if="categoriesArray < 1" class="map-icon-container">
      <img src="../../../assets/map-directory/MapUI.svg" alt="" />
      <p>0 Categories Exist</p>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import MapCategoriesCard from "../../../components/MapDirectory/Categories/MapCategoriesCard/MapCategoriesCard.vue";

export default {
  name: "MapCategoriesList",

  components: {
    MapCategoriesCard,
  },

  data: () => ({
    api: process.env.VUE_APP_MAPDIRECTORYAPI,
    categoriesArray: null,
  }),

  mounted() {
    this.fetchCategoriesArray();
  },

  methods: {
    addNew() {
      this.$router.push({ path: "/map-directory/categories/create" });
    },

    fetchCategoriesArray() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/categories`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.categoriesArray = response.data.data;
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.log("Error fetching categories array", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    deleteCategory(id) {
      if (
        confirm(
          "Are you sure you want to delete this Category? This is irreversible."
        )
      ) {
        this.$store.commit("startLoading");
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${this.api}/categories/${id}`,
            headers: { Authorization: jwt },
          })
            .then((response) => {
              this.fetchCategoriesArray();
              this.$store.commit("completeLoading");
            })
            .catch((e) => {
              console.error("Problem deleting category", e);
              this.$store.commit("completeLoading");
            });
        });
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filters {
  max-width: 250px !important;
}

.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
  width: calc(100% - (var(--body-gutter) * 2));
}

.map-icon {
  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
</style>
